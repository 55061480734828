<br>
<div *ngIf="sale">
  <h4 class="space" class="line"><i class="fi-clipboard"></i>Dados</h4>
  <form>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label>Nome</label>
          <input type="text" class="form-control" name="sale.client.name" [(ngModel)]="sale.client.name" disabled>
        </div>
        <div class="form-group ">
          <label for="uname">CPF</label>
          <input type="text" class="form-control" name="sale.client.cpf_cnpj" [(ngModel)]="sale.client.cpf_cnpj" disabled>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Placa</label>
          <input type="text" class="form-control" name="sale.vehicle.plate" [(ngModel)]="sale.vehicle.plate" disabled>
        </div>
        <div class="form-group">
          <label>Modelo</label>
          <input type="text" class="form-control" name="sale.vehicle.model" [(ngModel)]="sale.vehicle.model" disabled>
        </div>
      </div>
    </div>
  </form>



  <div class="form-group" *ngIf="readxml">
    <form class="form">
      <h4 class="space" class="line" *ngIf="sale">NF-e</h4>

      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Desconto</label>
          <input type="text" class="form-control" name="readxml.discount"
            [ngModel]="readxml.discount | currency:'BRL':true " (ngModelChange)="readxml.discount=$event" disabled>
        </div>
        <div class="form-group col-md-3">
          <label for="uname">Preço bruto</label>
          <input type="text" class="form-control" name="readxml.gross_price"
            [ngModel]="readxml.gross_price | currency:'BRL':true " (ngModelChange)="readxml.gross_price=$event"
            disabled>
        </div>
        <div class="form-group col-md-3">
          <label>Preço liquido</label>
          <input type="text" class="form-control" name="readxml.net_price"
            [ngModel]="readxml.net_price | currency:'BRL':true " (ngModelChange)="readxml.net_price=$event" disabled>
        </div>
        <div class="form-group col-md-3">
          <label>Nome do produto</label>
          <input type="text" class="form-control" name="readxml.prod_name" [(ngModel)]="readxml.prod_name" disabled>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Quantidade</label>
          <input type="text" class="form-control" name="readxml.quantity" [(ngModel)]="readxml.quantity" disabled>
        </div>
        <div class="form-group col-md-3">
          <label>Preço unitário</label>
          <input type="text" class="form-control" name="readxml.unit_price"
            [ngModel]="readxml.unit_price | currency:'BRL':true " (ngModelChange)="readxml.unit_price=$event" disabled>
        </div>
        <div class="form-group col-md-3">
          <label>Número da nota</label>
          <input type="text" class="form-control" name="readxml.nfe_number" [(ngModel)]="readxml.nfe_number" disabled>
        </div>
        <div class="form-group col-md-3">
          <label>Data da emissão</label>
          <input type="text" class="form-control" name="readxml.emission" [ngModel]="readxml.emission | date:'medium'"
            (ngModelChange)="readxml.emission=$event" disabled>
        </div>

      </div>

    </form>
  </div>

  <div>
    <h4 class="space" class="line">Adicionar XML da NF-e</h4>
    <br>
    <input type="file" class="form-control-file" (change)="on_file_change($event)">
    <br>
  </div>

  <button *ngIf="this.selectedFile" type="button" class="btn btn-outline-success"
    (click)="save_read_xml()">Salvar</button>

  <button type="button" class="btn btn-outline-secondary" (click)="modalService.dismissAll()">Sair</button>

  <button type="button" style="float:right" class="btn btn-outline-danger" (click)="warning_remove()">Remover</button>
</div>
<br>
