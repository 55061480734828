import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  public warning_observable;
  public action_observable;

  constructor() { }

  static html_alert(title,html){
    Swal.fire({
      title: title,
      html:html,
    }).then((result)=> {
      
    })
  }

  static success_alert(title,text){
    Swal.fire(title, text, 'success')
  }

  static error_alert(title,text){
    Swal.fire(title, text, 'error')
  }

  static warn_alert(title,text){
    Swal.fire(title, text, 'warning')
  }

  warning_alert(title,text,html?){
    this.warning_observable = Observable.create(observer => {
      Swal.fire({
        title: title,
        text: text,
        html:html,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then((result)=> {
        if (result.value) {
          observer.next(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          observer.next(false);
        }
      })
    });
  }


  action_alert(title,text,html?){
    this.action_observable = Observable.create(observer => {
      Swal.fire({
        title: title,
        text: text,
        html:html,
        icon: 'success',
        confirmButtonText: 'OK',
      }).then((result)=> {
          observer.next(true);
      })
    });
  }

  //https://www.npmjs.com/package/sweetalert2

}
