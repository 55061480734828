<app-navbar></app-navbar>
<div class="table-responsive-lg">
    <nav class="navbar sticky navbar-light bg-light">
        <a class="navbar-brand">Leituras/ Abastecimentos</a>
        <form class="form-inline">
            <!-- <button class="btn"><i class="fa fa-home"></i></button>
                <button type="submit"></button>
                <button type="submit"></button> -->
        </form>
    </nav>
    <div class="card-content">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr class="serif">
                        <th scope="col">Cliente</th>
                        <th scope="col">QRCode</th>
                        <th scope="col">Identificador</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Valor</th>
                        <th scope="col">DH Leitura</th>
                        <th scope="col">Posto</th>
                        <th scope="col">Aut</th>
                        <th scope="col">Bico Info</th>
                        <th scope="col">Seq Abast</th>
                        <th scope="col">Num Encer</th>
                        <th scope="col">Bico</th>
                        <th scope="col">QTD(L)</th>
                        <th scope="col">Val Unit(R$)</th>
                        <th scope="col">Val TOTAL(R$)</th>
                        <th scope="col">Frentista</th>
                        <th scope="col">DH Abast</th>
                    </tr>
                </thead>

            </table>
        </div>
    </div>
</div>