import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { AlertsService } from '../../shared/services/alerts.service';
import { LoadingService } from '../../shared/services/loading.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { DomSanitizer } from '@angular/platform-browser';
import { Sale } from 'src/app/shared/models/sale.model';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  @ViewChild('reportmodal', { static: false }) reportmodal;
  @ViewChild('imagemodal', { static: false }) imagemodal;
  @ViewChild('search_modal', { static: false }) search_modal;
  public token_search="";
  public petrol_stations = [];
  public report_sale = new Sale(); ;
  public model;
  public page = 1;
  public user;
  public pageSize = 5;
  public sales;
  public selected_sale;
  public collectionSize;
  public sale_status=[];
  public image;
  public selected_sale_position;
  public total_nfe={
    "discount":0,
    "gross_price":0,
    "saldo":0,
    "net_price":0,
    "quantity":0,
    "irregular":0
  }
  public filters = {
    token:"",
    date_from:"",
    date_to:"",
    status:"CODIGO_GERADO",
    petrol_station:"",
    report:"",
    situation:""
  }

  constructor(
    private loading:LoadingService,
    public api: ApiService,
    private sanitizer:DomSanitizer,
    public modalService: NgbModal,
  ) {
    this.get_petrol_station();
  }

  ngOnInit() {
    this.logged_user();
  }

  generate_report(result){

    this.report_sale = result;

    let discount = 0;
    let gross_price = 0;
    let net_price = 0;
    let quantity = 0;
    let irregular = 0;

    result.map(element => {
      console.log(element);
      if(element.nfe){
        discount = discount + Number(element.nfe.discount);
        gross_price = gross_price + Number(element.nfe.gross_price);
        net_price = net_price + Number(element.nfe.net_price);
        quantity = quantity + Number(element.nfe.quantity);
        if(element.situation === "Irregular"){
          irregular = irregular + Number(element.nfe.discount);
        }
      }
    });
    this.total_nfe={
      "discount":Number(discount.toFixed(2)),
      "gross_price":Number(gross_price.toFixed(2)),
      "saldo":Number(Number(discount-irregular).toFixed(2)),
      "net_price":Number(net_price.toFixed(2)),
      "quantity":Number(quantity.toFixed(2)),
      "irregular":Number(irregular),
    }
    this.modalService.open(this.reportmodal, {windowClass : "report_modal"});
  }

  export(){
    const doc = new jsPDF('l', 'pt');
    autoTable(doc, {bodyStyles:{fontStyle:'bold'}, theme: 'grid',showHead: true, html: '#contentToConvertHeader'});
    autoTable(doc, {theme: 'grid',html: '#contentToConvert'});
    doc.save('relatório.pdf');
  }


  get_all_salles() {
    this.api.get_all_sales().
      subscribe(
        (result: any) => {
          console.log(result);
          this.sales = result;
        },
        error => {
          console.log("erro", error);
        }
      );
  }

  logged_user() {
    this.api.logged_user().
      subscribe(
        (result: any) => {
          this.user = result;
          if(this.user.is_adm){
            this.filters.status="NOTA_ENVIADA"
          }
          this.filter();
          this.get_sale_status();
        },
        error => {
          console.log("erro", error);
        }
      );
  }

  get_image(sale, position){
    this.selected_sale = sale;
    this.selected_sale_position = position;
    this.image = sale.image;
    this.open_image();
  }

  consult_token(token) {

    this.token_search=token
    const modal =this.modalService.open(this.search_modal,{ windowClass : "largeModal"})

    modal.result.then(() => {
    }, () => {
      this.filter();
    })

  }

  filter() {
    this.api.filter(this.filters, this.page, this.pageSize).subscribe(
      (result: any) => {
        console.log(result);

        this.collectionSize = result.count;


        if(this.filters.report){
          this.generate_report(result);
          return
        }

        this.sales = result.results;

        if(this.selected_sale){
          this.selected_sale = this.sales.find(element => element.id == this.selected_sale.id);
        }

      },
      error => {
        this.sales = null;
        AlertsService.error_alert("Erro ao buscar", "")
      }
    );
  }

  get_sale_status() {
    this.api.sale_status().subscribe(
      (status: any) => {
        this.sale_status = status;
      },
      error => {
        console.log(error);
      }
    );
  }

  open_image(){
    this.modalService.open(this.imagemodal,{ size: 'lg' });
  }

  situation_status(status){
    this.loading.present("Mudando status");
    this.api.change_situation(this.selected_sale.id,status).subscribe(
      (result: any) => {
        this.sales = this.sales.filter(element => element.id !== this.selected_sale.id);
        this.loading.dismiss();
        this.modalService.dismissAll();
        console.log(this.sales);
      },
      error => {
        this.loading.dismiss();
        AlertsService.error_alert("Erros ao mudar status","");
      }
    );
  }

  get_petrol_station(){
    this.api.petrol_station().subscribe(
      (result: any) => {
        this.petrol_stations = result;
      },
      error => {
        console.log(error);
      }
    );
  }

}
