import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private is_showing = false;
  public interval;
  loading_text = new Subject<string>();

  constructor(private spinner: NgxSpinnerService) {}

  present(text) {
    if (!this.is_showing) {
      this.loading_text.next(text);
      this.spinner.show();
      clearInterval(this.interval);
      this.start_counting();
      this.is_showing = true;
    }
  }

  present_no_time(text) {
    if (!this.is_showing) {
      this.loading_text.next(text);
      this.spinner.show();
      this.is_showing = true;
    }
  }

  start_counting() {
    this.interval = setTimeout(() => {
      this.dismiss();
    },60000);
  }

  dismiss() {
    if (this.is_showing) {
      clearInterval(this.interval);
      this.spinner.hide();
      this.is_showing = false;
    }
  }


  

}
