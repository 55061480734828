import { Cliente } from './client.model';
import { Vehicle } from './vehicle.model';
import { Nfe } from './nfe.model';

export class Sale  {
    client:Cliente;
    vehicle: Vehicle;
    created_at:any;
    id: any;
    nfe: Nfe;
    petrol_station: any;
    plate_image_path: any;
    situation: any;
    status: any;
    token: any;
    updated_at: any;
    user: any;
    xml_name:any;

   
}