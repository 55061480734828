<div class="main">
  <div class="section">
  <div class="container">
    <div class="row">
      <div class="container container--mini">
      <!-- <br>
      <h5>Bem vindo! Realize seu cadastro</h5>
      <br> -->
      <form class="form">
      
        <img src="../../assets/img/logo/logo-coopsafe.png" alt="" class="img-fluid mt-5 mx-auto d-block" width="150"
        height="150" />
        <div class="form-group">
          <label for="user.login">Nome</label>
          <input #name class="form-control"  type="text">
        </div>

        <div class="form-group">
          <label for="user.login">E-mail</label>
          <input #email class="form-control"  type="email">
        </div>

        <div class="form-group">
          <label for="user.login">Senha</label>
          <input #password class="form-control"  type="password">
        </div>
        <div class="form-row">
          <!--<div class="col-md-6 mb-3">
            <select class="form-control" #petrol_station_id>
              <option selected>Selecione o posto</option>
              <option *ngFor="let petrol of petrol_station" [value]=petrol.id>{{petrol.name}}</option>
            </select>
          </div>-->
          <!-- <div class="col">
              <div class="btn-group mr-2" role="group" aria-label="First group">
                  <button class="btn btn-primary" type="submit" (click)="filter(token.value,date_to.value,1)">Buscar</button>
              </div>
              <div class="btn-group mr-2" role="group" aria-label="First group">
                  <button class="btn btn-primary" (click)="generate_report()">Exportar PDF</button>
              </div>
          </div> -->
        </div>

        <button type="button" class="btn btn-outline-success"
          (click)="register(email.value,password.value,name.value)">CADASTRAR</button>

      </form>
      <br>
      <p class="small text-center text-gray-soft">
        Já tem uma conta?
        <a routerLink="/login">Login</a>
      </p>
    </div>
    </div>
  </div>
</div>
</div>