import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { combineAll } from 'rxjs/operators';
import { AlertsService } from '../../shared/services/alerts.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  error: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private loading:LoadingService,
  ){}


  ngOnInit(): void {
  }

  login(email: string, password: string) {
    this.loading.present("");
    this.authService.login(email, password).subscribe(
      success =>{
        this.loading.dismiss();
        this.router.navigate(['client-list']);
      },
      error =>{
        this.loading.dismiss();
        console.log(error);
        AlertsService.error_alert("Ops!",error.error);
        this.error = error
      }
    );
  }

  onKeydown(event) {
    console.log(event);
  }

  register_routing(){
    this.router.navigate(['register']);
  }

}
