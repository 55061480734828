import { Component, OnInit } from '@angular/core';
import {Register} from '../../shared/models/register'
import { Router } from '@angular/router';
import { ApiService } from '../../shared/services/api.service';
import { AlertsService } from '../../shared/services/alerts.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  // register=new Register();
  registers: Register[];
  error: any;
  public petrol_station;

  constructor(
    public api: ApiService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit()  {
    this.get_petrol_station();
  }

  register(email:string,password:string,name:string){
    this.api.register(email,password,name).subscribe(
    (result: any) => {
     console.log(result);
      AlertsService.success_alert('Sucesso',"Seu usuário foi criado com sucesso");
      this.router.navigate(["login"])
    }, error => {
      console.log(error);
      AlertsService.error_alert('Erro',"Erro ao cadastrar");
    });
  }

  get_petrol_station(){
    this.api.petrol_station().subscribe(
      (result: any) => {
        this.petrol_station = result;
      },
      error => {
        console.log(error);
      }
    );
  }
 
}
