
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/client-list">Coopsafe Combustível</a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto">
    
      <li class="nav-item">
        <a class="nav-link" routerLink="/client-list"><i class="fas fa-users"></i>Clientes</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <ul class="navbar-nav ml-auto">


        <li class="far fa-usernav-item" ngbDropdown display="dynamic" placement="bottom-right">
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
            <i class="far fa-user"></i>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
            <a ngbDropdownItem [routerLink]="['/perfil']">Perfil</a>
            <a ngbDropdownItem href="#" (click)="$event.preventDefault();warning_alert()">Sair</a>
          </div>

          
        </li>
      </ul>
    </form>
  </div>
</nav>

 