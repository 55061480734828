import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './pages/search/search.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PainelComponent } from './pages/painel/painel.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './pages/login/login.component';
import { ApiService } from './shared/services/api.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AuthService, AuthInterceptor, AuthGuard } from './shared/services/auth.service';
import { ClientListComponent } from './pages/client-list/client-list.component';
import { RegisterComponent } from './pages/register/register.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ColorsDirective } from './shared/directives/colors.directive';
import { DiscountsComponent } from './pages/discounts/discounts.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { QRCodeModule } from 'angularx-qrcode';
import { PerfilComponent } from './pages/perfil/perfil.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    PainelComponent,
    NavbarComponent,
    LoginComponent,
    ClientListComponent,
    RegisterComponent,
    CustomerDetailsComponent,
    ColorsDirective,
    DiscountsComponent,
    PerfilComponent,
  ],
  imports: [
    QRCodeModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgxMaskModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ApiService,
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
