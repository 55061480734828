<app-navbar></app-navbar>
<div class="container-fluid" *ngIf="user">
    <div class="table-responsive-lg">
        <br>
        <div class="form-row">
            <div class="col">
                <div class="form-group">
                    <label>Buscar</label>
                    <input type="text" class="form-control" name="filters.token" placeholder="Placa, Token, Nome, Cpf/Cnpj" [(ngModel)]="filters.token">
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label>Data Inicial</label>
                    <input class="form-control" type="date" name="filters.date_from" [(ngModel)]="filters.date_from">
                    <!-- <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div> -->
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label>Data Final</label>
                    <input class="form-control" type="date" name="filters.date_to" [(ngModel)]="filters.date_to">
                    <!-- <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div> -->
                </div>
            </div>
            <div class="col" *ngIf="user.is_adm">
                <div class="form-group">
                    <label>Posto</label>
                    <select id="inputState" class="form-control" name="filters.petrol_station" [(ngModel)]="filters.petrol_station">
                        <option selected value="">Todos</option>
                         <option *ngFor="let petrol of petrol_stations" [value]=petrol.id>{{petrol.name}}</option>
                    </select>
                </div>
            </div>
        </div>


        <div class="form-row">
            <div class="col">
                <label>Status</label>
                <select id="inputState" class="form-control" name="filters.status" [(ngModel)]="filters.status">
                    <option value="">Todos</option>
                    <option *ngFor="let status of sale_status" [value]=status[0]>{{status[1]}}</option>
                </select>
            </div>
            <div class="col">
              <label>Situação</label>
              <select id="inputState" class="form-control" name="filters.situation" [(ngModel)]="filters.situation">
                  <option value="" selected>TODOS</option>
                  <option value="REGULAR">Regular</option>
                  <option value="IRREGULAR">Irregular</option>
                  <option value="NAO_VERIFICADO">Não Verificado</option>
              </select>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Ações</label>
                <br>
                <button class="btn btn-primary" type="submit" (click)="this.filters.report='';page=1;filter()">Buscar</button>
                <button class="btn btn-secondary" (click)="this.filters.report=true;filter()">Relatório</button>
              </div>
            </div>

        </div>
        <br>
        <div class="card-content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr class="serif">
                            <th scope="col">Nome</th>
                            <th scope="col">Veículo</th>
                            <th scope="col">Placa</th>
                            <th scope="col">Desconto</th>
                            <th scope="col">Data</th>
                            <th scope="col">Token</th>
                            <th scope="col">Situação</th>
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor ="let sale of sales;let position = index">
                            <td>{{sale.client.name}}</td>
                            <td>{{sale.vehicle.model}}</td>
                            <td>{{sale.vehicle.plate}}</td>
                            <td *ngIf="sale.nfe"><b>{{sale.nfe.discount | currency:'BRL':true}}</b></td>
                            <td *ngIf="!sale.nfe"><b>Nfe inexistente</b></td>
                            <td>{{sale.created_at| date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{sale.token.split(' ')[0]}}</td>
                            <td textColors text={{sale.situation}}>{{sale.situation}}</td>
                            <td id="actions">
                                <i style="color:orange" *ngIf="sale?.nfe?.quantity>70" class="fas fa-exclamation-triangle"></i>
                                <i class="far fa-eye" (click)="consult_token(sale.token.split(' ')[0])"></i>
                                <i *ngIf="!sale.is_qrcode" class="fas fa-camera-retro" (click)="get_image(sale,position)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                *ngIf="collectionSize!=0"
                (pageChange)="page = $event; filter()"
                [collectionSize]="collectionSize"
                [(page)]="page"
                [maxSize]="'10'"
                [pageSize]="pageSize"
                [boundaryLinks]="false">
                </ngb-pagination>
                <select
                    *ngIf="collectionSize!=0"
                    class="custom-select"
                    style="width: auto; float:right"
                    [(ngModel)]="pageSize"
                    (change)="page=1;filter();">
                    <option [ngValue]="5">5 Itens por página</option>
                    <option [ngValue]="10">10 Itens por página</option>
                    <option [ngValue]="15">15 Itens por página</option>
                    <option [ngValue]="30">30 Itens por página</option>
                    <option [ngValue]="100">100 Itens por página</option>
                </select>
            </div>
        </div>
    </div>
</div>


<ng-template #reportmodal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Relatório</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body overflow-auto">
    <div class="table-responsive ">
        <table class="table table-striped" id="contentToConvertHeader">
            <thead>
                <tr class="serif">
                    <th class="col-xs-2">Regulares:</th>
                    <th class="col-xs-2">Irregulares:</th>
                    <th class="col-xs-2">Saldo</th>
                    <th class="col-xs-2">Preço bruto</th>
                    <th class="col-xs-2">Preço liquido</th>
                    <th class="col-xs-2">Litros</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="col-xs-2">{{total_nfe.discount | currency:'BRL':true}}</td>
                    <td style="color:red"class="col-xs-2">{{total_nfe.irregular | currency:'BRL':true}}</td>
                    <td class="col-xs-2"><b>{{total_nfe.saldo | currency:'BRL':true}}</b></td>
                    <td class="col-xs-2">{{total_nfe.gross_price | currency:'BRL':true}}</td>
                    <td class="col-xs-2">{{total_nfe.net_price | currency:'BRL':true}}</td>
                    <td class="col-xs-2">{{total_nfe.quantity}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive">
        <table class="table table-striped" id="contentToConvert">
            <thead>
                <tr class="serif">
                    <th scope="col">Nome</th>
                    <th scope="col">Veículo</th>
                    <th scope="col">Placa</th>
                    <th scope="col">Data</th>
                    <th scope="col">Situação</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">V. Unit</th>
                    <th scope="col">V. Bruto</th>
                    <th scope="col">V. Liquido</th>
                    <th scope="col">Desconto</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor ="let sale of report_sale">
                    <td>{{sale.client.name.split(' ')[0]}} {{sale.client.name.split(' ')[sale.client.name.split.length-1]}}</td>
                    <td>{{sale.vehicle.model.split(' ')[0]}}</td>
                    <td>{{sale.vehicle.plate}}</td>
                    <td>{{sale.created_at| date: 'dd/MM/yyyy HH:mm'}}</td>
                    <td textColors text={{sale.situation}}>{{sale.situation}}</td>
                    <td><span *ngIf="sale.nfe">{{sale.nfe.quantity}}</span></td>
                    <td><span *ngIf="sale.nfe">{{sale.nfe.unit_price | currency:'BRL':true }}</span></td>
                    <td><span *ngIf="sale.nfe">{{sale.nfe.gross_price | currency:'BRL':true }}</span></td>
                    <td><span *ngIf="sale.nfe">{{sale.nfe.net_price | currency:'BRL':true }}</span></td>
                    <td><span *ngIf="sale.nfe">{{sale.nfe.discount | currency:'BRL':true }}</span></td>


                </tr>
            </tbody>
        </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="export()">Exportar</button>
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Sair</button>
  </div>
</ng-template>


<ng-template #imagemodal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Imagem</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="text-align:center">
        <h4>{{selected_sale.vehicle.plate}}</h4>
        <h5>{{selected_sale.situation}}</h5>
    </div>
    <div class="d-flex justify-content-center">
        <!--<i class="fas fa-arrow-left"  id="arrow-left"  (click)="change_sale('ArrowLeft')"></i>-->
        <img id="car_image" [src]="this.image" style="max-height:400px">
        <!--<i class="fas fa-arrow-right" id="arrow-right" (click)="change_sale('ArrowRight')"></i>-->
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" *ngIf="user.is_adm" class="btn btn-outline-danger" (click)="situation_status('IRREGULAR')">Irregular</button>
    <button type="button" *ngIf="user.is_adm" class="btn btn-outline-success" (click)="situation_status('REGULAR')">Regular</button>
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Sair</button>
  </div>

</ng-template>


<ng-template #search_modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="container-fluid">
    <app-search [token_search]="token_search"></app-search>
  </div>

  <div class="modal-footer">

  </div>

</ng-template>
