import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { AlertsService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  constructor(
    private api: ApiService,
    private router: Router,
    private loading:LoadingService,
  ) { }

  ngOnInit(): void {
  }

  save_password(password){
    this.loading.present("Modificando senha...");
    this.api.change_password(password).subscribe(
      success =>{ 
        this.loading.dismiss();
        this.router.navigate(['login']);
        AlertsService.success_alert("Senha modificado com sucesso","");
      },
      error =>{
        this.loading.dismiss();
        console.log(error);
        AlertsService.error_alert("Ops!",error.error);
      }
    );

  }



}
