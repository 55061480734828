import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {
  public discount_form: FormGroup;
  public fipes = [];
  public req_control = false;
  public token_qrcode = null;
  public generated_code = "";

  constructor(private loading:LoadingService,private api:ApiService) { 
    this.discount_form = new FormGroup({
      'whats': new FormControl('', [Validators.required]),
      'name': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required,Validators.email]),
      'plate': new FormControl('', [Validators.required,Validators.min(7)]),
      'vehicle_type': new FormControl('', [Validators.required]),
      'selected_fipe': new FormControl('', [Validators.required]),
    });

  }

  ngOnInit(): void {
    let token_qrcode = localStorage.getItem("token_qrcode");
    console.log
    if(token_qrcode){
      this.token_qrcode = localStorage.getItem("token_qrcode");
    }
  }

  generate_discount(){

    if(!this.discount_form.valid){
      AlertsService.error_alert("Preencha todos os campos","");
      this.api.markFormGroupTouched(this.discount_form);
      return false;
    }

    this.loading.present("Gerando Qrcode");

    let fipe = this.fipes[this.discount_form.get("selected_fipe").value];

    this.api.generate_qrcode(this.discount_form.getRawValue(),fipe).subscribe(
      (result:any) =>{ 
        console.log(result);
        this.loading.dismiss();
        this.token_qrcode = result.token;
        localStorage.setItem("token_qrcode",result.token);

        if(result.status === "CODIGO_GERADO"){
          this.generated_code = "Ja foi gerado um código de abastecimento para este veículo";
        }
      },
      error =>{
        this.loading.dismiss();
        AlertsService.error_alert("Erro ai buscar veículo","");
      }
    );
  }

  consult_plate() {
    
    let {vehicle_type,plate} = this.discount_form.value;
    plate = plate.toUpperCase();
    this.discount_form.patchValue({
      "plate":plate
    })


    console.log(plate);


    if(this.req_control){
      return false;
    }

    if(!vehicle_type){
      AlertsService.error_alert("Selecione o tipo do veículo","");
      return false;
    }

    if(!plate || plate.length<7){
      return false;
    }

    this.loading.present("Buscando veículos");
    this.req_control = true;
    this.api.consult_plate(plate, vehicle_type).subscribe(
      (result:any) =>{ 
        this.req_control=false;
        this.loading.dismiss();
        this.fipes=result.fipes;
      },
      error =>{
        this.req_control=false;
        this.loading.dismiss();
        AlertsService.error_alert("Veículo não encontrado","");
      }
    );
  }
}
