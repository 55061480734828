<app-navbar></app-navbar>
<div class="d-flex justify-content-center" style="margin-top:15px">
    <div class="card" style="width: 18rem;">

    <div class="card-body">
        <h5 class="card-title">Modificar senha</h5>
        <form>
            <div class="form-group">
                <label for="exampleInputEmail1">Digite sua nova senha</label>
                <input type="text" class="form-control" #password>
            </div>        
        </form>
        <button type="button" class="btn btn-primary" (click)="save_password(password.value)">Salvar</button>


    </div>
    </div>
</div>