import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { AlertsService } from '../shared/services/alerts.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public isCollapsed=true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alerts:AlertsService
  ) { }

  ngOnInit(): void {
    if(this.logout){

    }
    
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['login']);
    if(!this.authService.logout){
     error=>{
       console.log(error);
     }  
    }
  }

  warning_alert(){
    this.alerts.warning_alert("Sair","Deseja realmente sair ? ");
    let warning_observable = this.alerts.warning_observable.subscribe(data => {
      if(data){
        this.logout();
      }
      warning_observable.unsubscribe();
    },
    () => {});
  }


 

}
