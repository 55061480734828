import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { FormGroup } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public version_number = "0";
  private apiRoot = AuthService.url;

  constructor(private http: HttpClient,private router:Router) {
  }

  change_password(password){
    return this.http.post(this.apiRoot.concat('user/change_password/'),{
      password
    })
  }

  change_situation(sale_id,situation){
    return this.http.post(this.apiRoot.concat('sale/change_situation/'),{
      "sale_id":sale_id,
      "situation":situation
    })
  }

  consult_plate(plate,vehicle_type){
    return this.http.post(this.apiRoot.concat('fipe/consult/'),{
      "plate":plate,
      "vehicle_type":vehicle_type
    })
  }

  generate_qrcode(discount,fipe){
    return this.http.post(this.apiRoot.concat('discount/generate_qrcode/'),{
      discount,fipe
    })
  }

  sale_status(){
    return this.http.get(this.apiRoot.concat('sale/status/'));
  }

  get_image(id){
    return this.http.get(this.apiRoot.concat('sale/get_image/?sale_id='+id));
  }

  save_read_xml(uploadData,token){
    return this.http.post(this.apiRoot.concat('sale/save_read_xml/?token='.concat(token)),uploadData,{
    })
  }

  read_xml(uploadData){
    return this.http.post(this.apiRoot.concat('sale/read_xml/'),uploadData,{
    })
  }

  get_client(token){
    return this.http.get(this.apiRoot.concat('client/find_client/?token='+token));
  }

  get_all_sales(){
    return this.http.get(this.apiRoot.concat('sale/'));
  }

  filters(token){
    return this.http.get(this.apiRoot.concat('sale/filter/?search='+token));

  }

  filter(filters, page, pageSize){

    console.log(filters);

    return this.http.get("".concat(this.apiRoot,
    'sale/filter/?search=',filters.token,
    '&date_gte=',filters.date_from,
    '&date_lte=',filters.date_to,
    '&page=',page,
    '&status=',filters.status,
    '&page_size=',pageSize,
    '&petrol_station_id=',filters.petrol_station,
    '&situation=',filters.situation,
    '&report=',filters.report));
  }

  register(email:string,password:string,name:string){
    return this.http.post(this.apiRoot.concat('authentication/create_user/'),{

      "email":email,
      "password":password,
      "name":name

    });
  }

  logged_user(){
    return this.http.get(this.apiRoot.concat('user/logged/'));
  }

  petrol_station(){
    return this.http.get(this.apiRoot.concat('petrol-station/'));
  }

  removeSale(id){
    return this.http.delete(this.apiRoot.concat(`sale/${id}`));
  }


   /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
