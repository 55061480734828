<div class="main">
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="container container--mini">

          <form class="form">
            <!-- <h3>Login</h3> -->
            <img src="../../assets/img/logo/logo-coopsafe.png" alt="" class="img-fluid mt-5 mx-auto d-block" width="150"
            height="150" />

            <div class="form-group">
              <label class="font-weight-normal">E-mail</label>
              <input #email type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                required>
            </div>
            <div class="form-group">
              <label class="font-weight-normal">Senha</label>
              <input #password class="form-control" type="password">
            </div>

            <button type="submit" class="btn btn-outline-success"
              (click)="login(email.value, password.value)" (keydown.enter)="onKeydown($event)">LOGIN</button>
            <!-- <br>
          <br>
          <button type="submit" class="btn btn-outline-primary" (click)="register_routing()">CADASTRA</button> -->

          </form>
          <br>
          <p class="small text-center text-gray-soft font-weight-normal">
            Ainda não tem conta?
            <a routerLink="/register"> Cadastre-se</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
  
