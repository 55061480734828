<div class="container-fluid bg-light py-3">
    <div class="row">
        <div class="col-md-6 mx-auto">
                <div class="card card-body" [formGroup]="discount_form">
                    <h3 class="text-center mb-4">Preencha seus dados</h3>

                    <fieldset>

                        <div class="form-group">
                            <select class="form-control" formControlName="vehicle_type">
                                <option value="">Selecione o tipo do seu veículo</option>
                                <option value="carros">Carro</option>
                                <option value="motos">Moto</option>
                            </select>
                            <small class="form-text" *ngIf="!this.discount_form.get('vehicle_type').valid && this.discount_form.get('vehicle_type').touched">
                                Tipo de veículo inválido.
                            </small>
                        </div>

                        <div class="form-group has-error">
                            <input mask="AAA-AAAA" formControlName="plate" (keyup)="consult_plate()" class="form-control input-lg" placeholder="Digite a placa do veículo" name="plate" type="text">
                            <small class="form-text" *ngIf="!this.discount_form.get('plate').valid && this.discount_form.get('plate').touched">
                                Placa inválida.
                            </small>
                        </div>

                        <div class="form-group" *ngIf="fipes.length>0">
                            <select class="form-control input-lg"  formControlName="selected_fipe">
                                <option value="">Selecione o seu veículo</option>
                                <option *ngFor="let fipe of fipes;let i = index" [value]="i">{{fipe.Modelo}} - {{fipe.AnoModelo}} - {{fipe.Combustivel}}</option>
                            </select>
                            <small class="form-text" *ngIf="!this.discount_form.get('selected_fipe').valid && this.discount_form.get('selected_fipe').touched">
                                Modelo inválido.
                            </small>
                        </div>

                        <div class="form-group has-success">
                            <input class="form-control input-lg" placeholder="Nome" name="name" formControlName="name" type="text">
                            <small class="form-text" *ngIf="!this.discount_form.get('name').valid && this.discount_form.get('name').touched">
                                Nome inválido.
                            </small>
                        </div>

                        <div class="form-group has-success">
                            <input class="form-control input-lg" mask="(00) 00000-0000" placeholder="Whats/Celular" name="whats" formControlName="whats" value="" type="text">
                            <small class="form-text" *ngIf="!this.discount_form.get('whats').valid && this.discount_form.get('whats').touched">
                                Whats inválido.
                            </small>
                        </div>

                        <div class="form-group has-success">
                            <input class="form-control input-lg" placeholder="E-mail" name="email" value=""  formControlName="email" type="text">
                            <small class="form-text" *ngIf="!this.discount_form.get('email').valid && this.discount_form.get('email').touched">
                                E-mail inválido.
                            </small>
                        </div>


                        <input class="btn btn-lg btn-primary btn-block" (click)="generate_discount()"value="Gerar QRCODE para o desconto" >
                        <br>
                        <h4 *ngIf="token_qrcode && !generated_code">Qrcode gerado</h4>
                        <h5 *ngIf="generated_code">{{generated_code}}</h5>
                        <br>
                        <div *ngIf="token_qrcode" class="d-flex justify-content-center">
                            <qrcode [qrdata]="token_qrcode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                        </div>

                    </fieldset>
                </div>
        </div>
    </div>
</div>
  
