import { Directive, Input, OnInit, HostBinding } from '@angular/core';

@Directive({
  selector: '[textColors]'
})
export class ColorsDirective implements OnInit{

  @Input() text: string; //receives the red color 
  @HostBinding('style.color') color: string = "black"; //atribute selector we want to change


  constructor() { }

  ngOnInit() {

    const colors = [
      {
        "type":"Irregular",
        "color":"red"
      },
      {
        "type":"Regular",
        "color":"green"
      },
      {
        "type":"Irregular",
        "color":"red"
      },
    ]


    const found = colors.find(color => color.type === this.text);

    if(found){
      this.color = found.color
    }

  }

}
