import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{SearchComponent} from './pages/search/search.component';
import {PainelComponent} from './pages/painel/painel.component';
import {LoginComponent} from './pages/login/login.component';
import {CustomerDetailsComponent} from './pages/customer-details/customer-details.component';
import {RegisterComponent} from './pages/register/register.component';
import {ClientListComponent} from './pages/client-list/client-list.component';
import { AuthGuard } from  './shared/services/auth.service';
import { DiscountsComponent } from './pages/discounts/discounts.component';
import { PerfilComponent } from './pages/perfil/perfil.component';

const routes: Routes = [
  {path: 'search', component:SearchComponent,canActivate: [AuthGuard]},
  {path: 'search/:id', component:SearchComponent,canActivate: [AuthGuard]},
  {path: 'painel', component:PainelComponent,canActivate: [AuthGuard]},
  {path: 'client-list', component:ClientListComponent,canActivate: [AuthGuard]},
  {path: 'customer-details',component:CustomerDetailsComponent,canActivate:[AuthGuard]},
  {path: 'perfil',component:PerfilComponent,canActivate:[AuthGuard]},
  {path: 'register', component:RegisterComponent},
  {path: 'login', component:LoginComponent},
  {path: 'desconto', component:DiscountsComponent},
  {path: '', component:LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
