import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, tap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../shared/services/api.service';
import { AlertsService } from '../../shared/services/alerts.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import {Sale} from '../../shared/models/sale.model'
import {Cliente} from '../../shared/models/client.model'
import {Vehicle} from '../../shared/models/vehicle.model'
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public client_form: FormGroup;
  public serial_form:FormGroup;
  public selectedFile: File;
  public certificate_password;
  public certificate;
  public date;
  public sale;
  public readxml;
  @Input() token_search;



  constructor(
    public api: ApiService,
    private route: ActivatedRoute,
    private loading:LoadingService,
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    public http: HttpClient,
    private alerts:AlertsService,
    public modalService: NgbModal,
    private router:Router)
     {
    this.serial_form = new FormGroup({
      'token_search': new FormControl('', [Validators.minLength(6)]),
    });

  }
  on_file_change(event) {
    this.selectedFile = event.target.files[0];
    this.read_xml();
  }

  ngOnInit() {
    if(this.token_search){
      this.serial_form.setValue({'token_search':this.token_search});
      this.consult_token();
    }
  }

  consult_token(){
    if(!this.serial_form.controls.token_search.valid){
      return;
    }
    this.api.filters(this.serial_form.controls.token_search.value).subscribe(
      (result: any)=>{
        this.sale=result[0];
        if(this.sale.nfe){
          this.readxml=this.sale.nfe;
        }
    },
    error => {
      this.sale=null;
      AlertsService.error_alert("Nada encontrado","");
      console.log(error);
    }
    );

  }


  save_read_xml() {
    this.loading.present("Salvando");
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile, this.selectedFile.name);
    this.api.save_read_xml(uploadData, this.token_search).subscribe((result: any) => {
      this.loading.dismiss();
      AlertsService.success_alert('Salvo','arquivo salvo com sucesso');
      console.log(result);

    }, error => {
      this.loading.dismiss();
      AlertsService.error_alert("Erro",error.error);
      console.log(error);
    });
  }

  read_xml(){
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile, this.selectedFile.name);
    this.api.read_xml(uploadData).subscribe((result: any) => {
      this.readxml=result;
    }, error => {
      AlertsService.error_alert("Erro",error.error);
      console.log(error);
    });
  }

  remove(){
    this.api.removeSale(this.sale.id).subscribe((result: any) => {
      AlertsService.success_alert("Removido com sucesso!","");
      this.modalService.dismissAll();
    }, error => {
     AlertsService.error_alert("Erro ao remover","");
     console.log(error);
   });
  }

  warning_remove(){
    this.alerts.warning_alert("Aceitar","Deseja realmente remover este registro?");
    let warning_observable = this.alerts.warning_observable.subscribe(data => {
      if(data){
        this.remove();
      }
      warning_observable.unsubscribe();
    },
    () => {});
  }





  // on_file_change(event){
  // console.log(event);
  // const selectedFiles=<FileList>event.srcElement.files;
  // // document.getElementById('customFilelabel').innerHTML=selectedFiles[0].name;
  // const fileNames=[];
  // for (let i=0;i<selectedFiles.length; i++){
  //   fileNames.push(selectedFiles[i].name);
  // }
  // document.getElementById('customFilelabel').innerHTML=fileNames.join(' , ');
  // }

  //



}
