import { Component } from '@angular/core';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Coopsafe Combustível';
  loading_text = "";

  constructor(private loading:LoadingService) {

    this.loading.loading_text.subscribe(text=>{
        this.loading_text = text;
    })

  }
}
